import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import ContactThree from "../elements/contact/ContactThree";


class Contact extends Component{

    render(){
        return(
            <React.Fragment>
                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190"  data-black-overlay="9">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Contact</h2>
                                    <p>The most important part of getting started... is getting started!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactThree />
                </div>
                {/* End Contact Page Area  */}

        


            </React.Fragment>
        )
    }
}
export default Contact