import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./../index.scss"
import Contact from "../elements/Contact"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Contact"
      description="Contact Coach John Mercer Today! New clients get a free exploratory conversation upon request! Setup yours now!"
      lang='english'
    />
    <Contact />
  </Layout>
)

export default IndexPage
